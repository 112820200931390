<template>
  <validation-observer ref="deliveryMethodsObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group label="Rodzaj">
          <validation-provider #default="{ errors }" name="Rodzaj" rules="required">
            <v-select
              id="v-category"
              label="name"
              placeholder="Wybierz rodzaj"
              v-model="form.type"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="typeOptionsToShow"
              :disabled="form.type.value === 'newsletter'" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nazwa">
          <validation-provider #default="{ errors }" name="Nazwa" rules="required|min:3|max:255">
            <b-form-input v-model="form.code" placeholder="Nazwa" :disabled="!form.type.value" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="`Wartość ${
            ['percent', 'delivery', 'newsletter'].includes(form.type.value) ? 'w procentach' : 'w zł'
          }`">
          <validation-provider #default="{ errors }" name="Wartość" rules="required">
            <b-form-input
              type="number"
              step="0.01"
              min="1"
              :max="form.type.value === 'percent' ? 100 : 100000"
              v-model="form.value"
              placeholder="Wartość"
              :disabled="!form.type.value || form.type.value === 'delivery'" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Liczba dostępnych kuponów">
          <b-form-input
            type="number"
            step="1"
            min="0"
            max="10000"
            v-model="form.amount"
            placeholder="Liczba dostępnych kuponów"
            :disabled="!form.type.value" />
        </b-form-group>

        <b-form-group label="Email - uzupełnić tylko, jeżeli przeznaczony dla jednej osoby">
          <validation-provider #default="{ errors }" name="Email" rules="email">
            <b-form-input v-model="form.email" placeholder="Email" :disabled="!form.type.value" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Data ważności - zostawić puste dla bezterminowej ważności">
          <b-form-input
            type="date"
            v-model="form.expired_date"
            placeholder="Data ważności"
            :disabled="!form.type.value" />
        </b-form-group>

        <b-form-group class="mt-2" label="Aktywny">
          <b-form-checkbox v-model="form.is_active"> Kod rabatowy będzie możliwy do wykorzystania </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin.vue';

export default {
  name: 'DeliveryMethodsFormComponent',
  mixins: [NotificationMixin],
  props: {
    discountCode: {
      type: Object,
      default: () => {
        return {
          code: null,
          value: null,
          email: null,
          type: {
            value: null,
            name: null,
          },
          amount: 0,
          expired_date: null,
          is_active: false,
        };
      },
    },
    isCreateView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      typeOptions: [
        {
          value: 'percent',
          name: 'Procentowy',
        },
        {
          value: 'amount',
          name: 'Kwotowy',
        },
        {
          value: 'delivery',
          name: 'Na dostawę',
        },
        {
          value: 'newsletter',
          name: 'Newsletter',
        },
      ],

      form: this.discountCode,
    };
  },

  watch: {
    async discountCode(value) {
      const option = this.typeOptions.find((option) => option.value === value.type);

      this.form = {
        ...this.discountCode,
        type: option,
      };
    },
    'form.type': {
      handler(value) {
        if (value.value === 'delivery') {
          this.form.value = 100;
        }
      },
      deep: true,
    },
  },

  computed: {
    typeOptionsToShow() {
      return this.typeOptions.filter((option) => option.value !== 'newsletter');
    },
  },

  methods: {
    onSubmit() {
      const data = {
        ...this.form,
        type: this.form.type.value,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.deliveryMethodsObserver.validate().then((success) => {
        if (!success) {
          this.showFormValidationNotification();
        }

        if (success) {
          const areAdditionalFieldsValid = this.additionalFieldValidation();

          if (!areAdditionalFieldsValid) {
            return;
          }

          return this.onSubmit();
        }
      });
    },
    additionalFieldValidation() {
      if (this.form.type.value === 'percent' && this.form.value > 100) {
        this.showErrorNotification('Błąd walidacji', 'Wartość nie może być większa niż 100%');

        return false;
      }

      if (this.form.type.value === 'amount' && this.form.value > 1000) {
        this.showErrorNotification('Błąd walidacji', 'Wartość nie może być większa niż 1000 PLN');

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
