var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"deliveryMethodsObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Rodzaj"}},[_c('validation-provider',{attrs:{"name":"Rodzaj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-category","label":"name","placeholder":"Wybierz rodzaj","clearable":false,"deselectFromDropdown":false,"options":_vm.typeOptionsToShow,"disabled":_vm.form.type.value === 'newsletter'},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nazwa"}},[_c('validation-provider',{attrs:{"name":"Nazwa","rules":"required|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nazwa","disabled":!_vm.form.type.value},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":("Wartość " + (['percent', 'delivery', 'newsletter'].includes(_vm.form.type.value) ? 'w procentach' : 'w zł'))}},[_c('validation-provider',{attrs:{"name":"Wartość","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.01","min":"1","max":_vm.form.type.value === 'percent' ? 100 : 100000,"placeholder":"Wartość","disabled":!_vm.form.type.value || _vm.form.type.value === 'delivery'},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Liczba dostępnych kuponów"}},[_c('b-form-input',{attrs:{"type":"number","step":"1","min":"0","max":"10000","placeholder":"Liczba dostępnych kuponów","disabled":!_vm.form.type.value},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),_c('b-form-group',{attrs:{"label":"Email - uzupełnić tylko, jeżeli przeznaczony dla jednej osoby"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email","disabled":!_vm.form.type.value},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Data ważności - zostawić puste dla bezterminowej ważności"}},[_c('b-form-input',{attrs:{"type":"date","placeholder":"Data ważności","disabled":!_vm.form.type.value},model:{value:(_vm.form.expired_date),callback:function ($$v) {_vm.$set(_vm.form, "expired_date", $$v)},expression:"form.expired_date"}})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Aktywny"}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v(" Kod rabatowy będzie możliwy do wykorzystania ")])],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }